import React, { useEffect, useState } from "react";
import { Button, Input, Col, Row, Label, Modal, FormGroup, Alert } from "reactstrap";
import { useNavigate, Link } from "react-router-dom";
import { getDatapointRegionalData, deteleDatapointItem } from "../../helpers/backend_helper";

import Select from "react-select";
import Spinner from "../../components/Spinner/index";

const Main = (props) => {
  const {
    loadModal,
    setLoadModal,
    setValue,
    value,
    setMoreDetails,
    moreDetails,
    isSubmitting,
    isError,
    errorMessage,
    selectedItem,
    regions,
    handleRefresh,
    defaultPeriodType,
    defaultPeriod,
    selectedYear,
    enteredDetails,
    handleRegionChange,
    handleSubmit,
    submissionNo,
    inputState
  } = props;

  const [isDataPointRegionalDataLoading, setIsDataPointRegionalDataLoading] =
    useState(false);
  const [datapointRegionalData, setDatapointRegionalData] = useState([]);
  const [totals, setTotals] = useState("")

  const handleOnChange = (event) => {
    const value = event.target.value;
    setValue(value);
  };

  const handleMoreDetails = (event) => {
    const value = event.target.value;
    setMoreDetails(value);
  };

  const handleClose = () => {
    setLoadModal(!loadModal)
    setValue("")
    setMoreDetails("")
  }

  const handleDelete = async (id) =>{
    await deteleDatapointItem(id).then((res) => console.log(res.status));

    // This two steps ensure the two tables are in sync
    handleRefresh();
  }
  

  const handleOnClick = () => {
    handleSubmit(selectedItem.data_point_id, value, moreDetails);

    setValue('')
    setMoreDetails('')
  }

  const fetchDatapointRegionalDraftData = async () => {
    setDatapointRegionalData([]);
    setIsDataPointRegionalDataLoading(true);

    const filters = {
      datapointId: selectedItem.data_point_id,
      defaultPeriodTypeId: defaultPeriodType.value,
      defaultPeriodId: defaultPeriod.value,
      year: selectedYear.getFullYear(),
    };

    try {
      await getDatapointRegionalData(filters).then((res) => {
        if (res === false) {
          navigate("/logout");
        } else {
          setDatapointRegionalData(res["data"]);
          setTotals(res["totals"]["total_value"]);
          console.log("Regional Data", res["data"]);
        }
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    setIsDataPointRegionalDataLoading(false);
  };
  

  useEffect(() => {
    fetchDatapointRegionalDraftData();
  }, [submissionNo]);

  let navigate = useNavigate();
  let nf = new Intl.NumberFormat();

  return (
    <>
      <Modal
        size="xl"
        isOpen={loadModal}
        toggle={() => {
          setLoadModal(!loadModal);
        }}
      >
        <div className="modal-header">
          <div className="flex justify-between">
            <h1 className="text-2xl ml-2">{selectedItem.data_point__label}</h1>
            <button
              className="absolute top-2 right-2 text-2xl font-bold text-gray-600 hover:text-gray-900"
              onClick={handleClose}
            >
              &times;
            </button>
          </div>
        </div>
        <div className="modal-body">
          {inputState === "editable" ?
          <>
          <Row>
            {isError ? (
              <Alert color="danger">
                <div>{errorMessage}</div>
              </Alert>
            ) : null}
          </Row>
          <Row>
            <Col className="mt-2" xs={12} md={6} lg={6}>
              <Select
                placeholder="Select Region..."
                options={regions}
                onChange={handleRegionChange}
              />
            </Col>
            <Col className="mt-2" xs={12} md={3} lg={3}>
              <Input type="number" onChange={handleOnChange} value={value} />
            </Col>
            <Col className="mt-2" xs={12} md={3} lg={3}>
              <Button
                color="success"
                onClick={handleOnClick}
              >
                {isSubmitting ? "Please wait ..." : "Save Data"}
              </Button>
            </Col>
            <Col className="mt-2" xs={12} md={9} lg={9}>
              <Input
                type="textarea"
                value={moreDetails}
                placeholder="Please enter any further details for this data point. eg. File references or events"
                onChange={handleMoreDetails}
              />
            </Col>
          </Row>
          </> : ""}

          <Row style={{ paddingTop: "2%" }}>
            <Col>
              {isDataPointRegionalDataLoading ? (
                <Spinner />
              ) : (
                <div className="table-responsive">
                <table className="table table-hover mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Region</th>
                      <th>Value</th>
                      <th>More Details</th>
                      <th>Period</th>
                      <th>Year</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {datapointRegionalData.map((item, index) => (
                      <tr key={index}>
                        <td style={{ width: "2rem" }}>{index + 1}</td>
                        <td style={{ width: "10rem" }}>
                          <h6>{item.region__description}</h6>
                        </td>
                        <td style={{ width: "10rem" }}>
                          <h6>{item.value}</h6>
                        </td>
                        <td style={{ width: "10rem" }}>
                          <h6>{item.more_details}</h6>
                        </td>
                        <td style={{ width: "10rem" }}>
                          <h6>{item.period__description}</h6>
                        </td>
                        <td style={{ width: "10rem" }}>
                          <h6>
                            {item.period__order === 1
                              ? `${item.year}/${item.year + 1}`
                              : `${item.year - 1}/${item.year}`}
                          </h6>
                        </td>

                        <td style={{ width: "10rem", textAlign: "center" }}>
                          {inputState === "editable" ?
                          <Link
                            className="btn-sm-danger"
                              onClick={(e) => handleDelete(item.id)}
                          >
                            Remove
                          </Link> : ""}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                    <td style={{ width: "2rem" }}></td>
                      <td className="text-2xl font-bold" style={{ width: "10rem" }}>TOTALS</td>            
                      <td className="text-2xl font-bold underline decoration-2 underline-offset-2" style={{ width: "10rem"}}>{nf.format(totals)}</td>
                      <td style={{ width: "10rem" }}></td>
                      <td style={{ width: "10rem" }}></td>
                      <td style={{ width: "10rem" }}></td>
                      <td style={{ width: "10rem" }}></td>
                    </tr>
                  </tfoot>
                </table>
                </div>
              )}
            </Col>
          </Row>

          <div style={{ paddingTop: "2%" }}>
            {selectedItem.state === "read-only" ? (
              <FormGroup>
                <Label>(Further details for the above value)</Label>
                <h3>{enteredDetails}</h3>
              </FormGroup>
            ) : (
              ""
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Main;
