
const SidebarData = [
  {
    label: "Menu",
    isMainMenu: true,
    module: '',
  },
  {
    label: "Dashboard",
    icon: "mdi mdi-home-variant-outline",
    isHasArrow: true,
    module: '',
    url: "/dashboard",
  },
  {
    label: "File Handling",
    module: 'files',
    isMainMenu: true,
  },
  {
    label: "File Management",
    icon: "mdi mdi-file-cabinet",
    isHasArrow: true,
    module: 'files',
    url: "/file-movement",
  },
  {
    label: "Statistics And Data Sharing",
    module: 'statistics',
    isMainMenu: true,
  },
  {
    label: "Data Entry Forms",
    icon: "mdi mdi-form-textbox",
    isHasArrow: true,
    module: 'statistics',
    url: "/data-entry",
  },
  {
    label: "Reports",
    module: 'statistics',
    isMainMenu: true,
  },
  {
    label: "Data Entry History",
    icon: "mdi mdi-format-page-break",
    module: 'statistics',
    isHasArrow: true,
    url: '/data-entry-history'
  },
  {
    label: "Log Framework",
    icon: "mdi mdi-timeline-check",
    module: '',
    url: '/logical-framework',
    isHasArrow: true,
  },
  {
    label: "Programme Progress",
    module: '',
    isMainMenu: true,
  },
  {
    label: "Activity Planner",
    icon: "mdi mdi-progress-clock",
    isHasArrow: true,
    module: '',
    url: "/activity-planner",
  },
  {
    label: "Milestone Reporting",
    icon: "mdi mdi-flag-checkered",
    isHasArrow: true,
    module: '',
    url: "/milestone-reporting",
  },
  {
    label: "Questionnaires",
    icon: "mdi mdi-format-page-break",
    isHasArrow: true,
    module: 'questionnaires',
    url: "/questionnaire-results",
  },
  {
    label: "Help and Support",
    module: '',
    isMainMenu: true,
  },
];


export default SidebarData;
