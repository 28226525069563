import React, { useEffect, useState } from "react";
import {
  postActivity,
  getActivities,
  deleteActivity,
} from "../../helpers/backend_helper";
import { useNavigate, Link } from "react-router-dom";

import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import {
  Card,
  Container,
  Button,
  Input,
  Col,
  Row,
  CardBody,
  Alert,
} from "reactstrap";
import Select from "react-select";
import Breadcrumbs from "../../components/Common/Breadcrumb";

const Main = () => {
  let navigate = useNavigate();

  const randomSubmissionNo = () => {
    const number = Math.floor(Math.random() * 10) + 1;
    return number;
  };

  const [isLoading, setIsLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState(new Date());
  const [showAlert, setShowAlert] = useState(false);
  const [color, setColor] = useState("");
  const [submissionNo, setSubmissionNo] = useState(randomSubmissionNo());
  const [activityNumber, setActivityNumber] = useState("");
  const [plannedActivityDetails, setPlannedActivityDetails] = useState("");
  const [plannedActivityTarget, setPlannedActivityTarget] = useState(0);
  const [plannedActivityStatus, setPlannedActivityStatus] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isYearChangeError, setIsYearChangeError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [activites, setActivities] = useState([]);

  const fetchActivities = async () => {
    setIsLoading(true);

    try {
      await getActivities().then((res) => {
        if (res === false) {
          navigate("/logout");
        } else {
          console.log("res", res);
          setActivities(res);
        }
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    setIsLoading(false);
  };

  const handleStatusChange = (event) => {
    setPlannedActivityStatus(event.value);
  };

  const handleAlertMessage = (message, color) => {
    setShowAlert(true);
    setColor(color);
    setErrorMessage(message);
  };

  // Handle events
  const handleSubmit = () => {
    setIsSubmitting(true);
    if (!plannedActivityStatus) {
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
      let message = "Please select a status!";
      handleAlertMessage(message, "danger");
      setIsSubmitting(false);
      return;
    }
    let obj = {
      activityYear: selectedYear.getFullYear(),
      activityNumber: activityNumber,
      plannedActivityDetails: plannedActivityDetails,
      plannedActivityTarget: plannedActivityTarget,
      plannedActivityStatus: plannedActivityStatus,
    };
    postActivity(obj)
      .then((res) => {
        if (res.status === 200) {
          setActivityNumber("");
          setPlannedActivityDetails("");
          setPlannedActivityTarget("");
          setPlannedActivityStatus("");

          setTimeout(() => {
            setShowAlert(false);
          }, 2000);

          setSubmissionNo(submissionNo + 1);
        }
      })
      .catch((err) => {
        setErrorMessage("Saving activity failed.");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 2000);
      });
    setIsSubmitting(false);
  };

  const activityOptions = [
    { value: "new", label: "New" },
    { value: "in_progress", label: "In Progress" },
    { value: "pending", label: "Pending" },
    { value: "complete", label: "Complete" },
  ];

  const handleYearChangeError = () => {
    setIsYearChangeError(true);
    setErrorMessage("Action not allowed");
    setTimeout(() => {
      setIsYearChangeError(false);
    }, 2000);
  };

  const handleYearChange = (event) => {
    if (new Date().getFullYear() !== new Date(event).getFullYear()) {
      handleYearChangeError();
    } else {
      setSelectedYear(new Date(event));
    }
  };

  const handleRefresh = () => {
    setSubmissionNo(submissionNo + 1);
  };

  const handleDelete = async (data) => {
    await deleteActivity(data).then((res) => {
      if (res.status === 200) {

        setTimeout(() => {
          setShowAlert(false);
        }, 2000);

        setSubmissionNo(submissionNo + 1);
      }
    })
    .catch((err) => {
      setErrorMessage("Saving activity failed.");
      setShowAlert(true);
      setColor("danger")
      setErrorMessage("Deleting record failed. This activity is linked to milestones.")
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    });

    handleRefresh();
  };

  useEffect(() => {
    fetchActivities();
  }, [submissionNo]);

  return (
    <>
      <div className="page-content">
        <Card>
          <CardBody>
            <Container fluid={true}>
              <Breadcrumbs
                title={localStorage.getItem("project")}
                breadcrumbItem="Activity Planner"
              />
            </Container>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <Row>
              <Col className="mt-4" xs={12} md={4} lg={4}>
                <label>Planned Activity Number</label>
                <Input
                  type="text"
                  value={activityNumber}
                  placeholder="eg: Activity v1.01"
                  onChange={(event) => setActivityNumber(event.target.value)}
                />
              </Col>

              <Col className="mt-4" xs={12} md={2} lg={2}>
                <label>Planned Activity Year</label>
                <Datetime
                  value={selectedYear}
                  dateFormat="YYYY"
                  timeFormat={false}
                  onChange={handleYearChange}
                  closeOnSelect
                />

                {isYearChangeError ? (
                  <Alert color="danger">{errorMessage}</Alert>
                ) : (
                  ""
                )}
              </Col>
            </Row>

            <Row>
              <Col className="mt-4" xs={12} md={6} lg={6}>
                <label>Planned Annual Activity</label>
                <Input
                  type="textarea"
                  value={plannedActivityDetails}
                  onChange={(event) =>
                    setPlannedActivityDetails(event.target.value)
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col className="mt-4" xs={12} md={3} lg={3}>
                <label>Planned Annual Activity Target</label>
                <Input
                  type="number"
                  value={plannedActivityTarget}
                  onChange={(event) =>
                    setPlannedActivityTarget(event.target.value)
                  }
                />
              </Col>

              <Col className="mt-4" xs={12} md={3} lg={3}>
                <label>Planned Activity Status</label>
                <Select
                  placeholder="Select Status..."
                  onChange={handleStatusChange}
                  options={activityOptions}
                />
              </Col>
            </Row>

            <Row>
              <Col className="mt-4" xs={12} md={9} lg={9}>
                <Button
                  color="primary"
                  className="d-grid"
                  onClick={handleSubmit}
                >
                  {isSubmitting ? "Please wait..." : "Save Activity"}
                </Button>
              </Col>
            </Row>

            <Row>
              {showAlert ? (
                <Col className="mt-4" xs={12} md={9} lg={9}>
                  <Alert color={color}>{errorMessage}</Alert>
                </Col>
              ) : (
                ""
              )}
            </Row>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <div className="table-responsive">
              <table className="table table-hover mb-0">
                <thead>
                  <tr>
                    <th style={{ width: "2rem" }}>#</th>
                    <th style={{ width: "6rem" }}>Activity Number</th>
                    <th style={{ width: "8rem" }}>Activity Details</th>
                    <th style={{ width: "2rem" }}>Activity Target</th>
                    <th style={{ width: "2rem" }}>Activity Status</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  {activites.map((item, index) => (
                    <tr key={index}>
                      <td style={{ width: "2rem" }}>{index + 1}</td>
                      <td style={{ width: "6rem" }}>{item.number}</td>
                      <td style={{ width: "8rem" }}>{item.description}</td>
                      <td style={{ width: "2rem" }}>{item.target}</td>
                      <td style={{ width: "2rem" }}>
                        {
                          activityOptions.filter(
                            (record) => record.value === item.status
                          )[0].label
                        }
                      </td>
                      <td style={{ width: "4rem", textAlign: "center" }}>
                        <Link
                          className="btn-sm-danger"
                          onClick={(e) => handleDelete(item.id)}
                        >
                          Remove
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>

                <tfoot>
                  <tr>
                    <td style={{ width: "2rem" }}></td>
                    <td style={{ width: "5rem" }}></td>
                    <td style={{ width: "10rem" }}></td>
                    <td style={{ width: "10rem" }}></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default Main;
