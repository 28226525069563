import React, { useEffect, useState } from "react";
import {
  getPeriodTypes,
  getPeriods,
  getPreviousPeriod,
  getDefaultPeriodType,
  getActivities,
  postMilestone,
  getMilestones,
  deleteMilestone,
} from "../../helpers/backend_helper";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

import {
  Card,
  Container,
  Button,
  Input,
  Col,
  Row,
  CardBody,
  Alert,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import { useNavigate, Link } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";

const Main = () => {
  let navigate = useNavigate();

  const randomSubmissionNo = () => {
    const number = Math.floor(Math.random() * 10) + 1;
    return number;
  };

  const [periods, setPeriods] = useState([]);
  const [defaultPeriod, setDefaultPeriod] = useState({});
  const [defaultPeriodType, setDefaultPeriodType] = useState({});
  const [periodTypes, setPeriodTypes] = useState([]);
  const [submissionNo, setSubmissionNo] = useState(randomSubmissionNo());

  const [showAlert, setShowAlert] = useState(false);
  const [color, setColor] = useState("");
  const [achievedActivityDetails, setAchievedActivityDetails] = useState("");
  const [achievedActivityValue, setAchievedActivityValue] = useState("");
  const [comments, setComments] = useState("");

  const [selectedPeriod, setSelectedPeriod] = useState();
  const [selectedPeriodType, setSelectedPeriodType] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedYear, setSelectedYear] = useState(new Date());
  const [sessionExpired, setSessionExpired] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState("");

  const [isYearChangeError, setIsYearChangeError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [activities, setActivities] = useState([]);
  const [milestones, setMilestones] = useState([]);

  const handleYearChangeError = () => {
    setIsYearChangeError(true);
    setErrorMessage("Action not allowed");
    setTimeout(() => {
      setIsYearChangeError(false);
    }, 2000);
  };

  const handleYearChange = (event) => {
    if (new Date().getFullYear() !== new Date(event).getFullYear()) {
      handleYearChangeError();
    } else {
      console.log(new Date(event));
      setSelectedYear(new Date(event));
    }
  };

  const fetchPeriods = async () => {
    setPeriods([]);

    try {
      await getPeriods(null).then((res) => {
        res.map((data) => {
          const value = data.id;
          const label = data.description;

          let dataObject = { value: value, label: label };

          setPeriods((current) => [...current, dataObject]);
        });
      });
    } catch (error) {
      setSessionExpired(true);
      console.error("Error fetching data:", error);
    }
  };

  const fetchPeriodTypes = async () => {
    setPeriodTypes([]);

    try {
      await getPeriodTypes(null).then((res) => {
        res.map((data) => {
          const value = data.id;
          const label = data.description;

          let dataObject = { value: value, label: label };

          setPeriodTypes((current) => [...current, dataObject]);
        });
      });
    } catch (error) {
      setSessionExpired(true);
      console.error("Error fetching data:", error);
    }
  };

  const fetchPreviousPeriod = async () => {
    try {
      await getPreviousPeriod(null).then((res) => {
        setDefaultPeriod(res);
        setSelectedPeriod(res.value);
      });
    } catch (error) {
      setSessionExpired(true);
      console.error("Error fetching data:", error);
    }
  };

  const fetchDefaultPeriodType = async () => {
    try {
      await getDefaultPeriodType(null).then((res) => {
        setDefaultPeriodType(res);
        setSelectedPeriodType(res.value);
      });
    } catch (error) {
      setSessionExpired(true);
      console.error("Error fetching data:", error);
    }
  };

  const fetchActivities = async () => {
    setShowLoader(true);
    try {
      setActivities([]);
      await getActivities().then((res) => {
        res.map((data) => {
          const value = data.id;
          const label = data.number;

          let dataObject = { value: value, label: label, number: data.number };

          setActivities((current) => [...current, dataObject]);
        });
      });
    } catch (error) {
      setSessionExpired(true);
      console.error("Error fetching data:", error);
    }

    setShowLoader(false);
  };

  const fetchMilestones = async () => {
    setIsLoading(true);

    try {
      await getMilestones().then((res) => {
        if (res === false) {
          navigate("/logout");
        } else {
          setMilestones(res);
        }
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    setIsLoading(false);
  };

  const handleAlertMessage = (message, color) => {
    setShowAlert(true);
    setErrorMessage(message);
    setColor(color);
  };

  const handleSubmit = () => {
    setIsSubmitting(true);

    if (!selectedActivity) {
      handleAlertMessage();
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
      let message = "Please select an activity!";
      handleAlertMessage(message, "danger");
      setIsSubmitting(false);
      return;
    }
    let obj = {
      year: selectedYear.getFullYear(),
      periodType: selectedPeriodType,
      period: selectedPeriod,
      selectedActivity: selectedActivity,

      achievedActivityDetails: achievedActivityDetails,
      achievedActivityValue: parseInt(achievedActivityValue),
      comments: comments,
    };

    postMilestone(obj)
      .then((res) => {
        if (res.status === 200) {
          setAchievedActivityDetails("");
          setAchievedActivityValue("");
          setComments("");
          setSelectedActivity("");

          setTimeout(() => {
            setShowAlert(false);
          }, 2000);

          setSubmissionNo(submissionNo + 1);
        }
      })
      .catch((err) => {
        setErrorMessage("Saving milestone failed.");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 2000);
      });

    setIsSubmitting(false);
  };

  const handleRefresh = () => {
    setSubmissionNo(submissionNo+1)
  }

  const handleDelete = async (data) => {
    await deleteMilestone(data).then((res) => console.log(res.status));

    handleRefresh();
  };

  // Call React Hooks
  useEffect(() => {
    fetchPreviousPeriod();
  }, []);

  useEffect(() => {
    fetchDefaultPeriodType();
  }, []);

  useEffect(() => {
    fetchPeriods();
  }, []);

  useEffect(() => {
    fetchPeriodTypes();
  }, []);

  useEffect(() => {
    fetchActivities();
  }, []);

  useEffect(() => {
    fetchMilestones();
  }, [submissionNo]);

  return (
    <>
      <div className="page-content">
        <Card>
          <CardBody>
            <Container fluid={true}>
              <Breadcrumbs
                title={localStorage.getItem("project")}
                breadcrumbItem="Milestone Reporting"
              />
            </Container>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <Row>
              <Col xs={12} md={4} lg={4}>
                <label>Period Type</label>
                <Select
                  placeholder="Select Period Type..."
                  options={periodTypes}
                  value={
                    selectedPeriodType > 0 && periodTypes.length > 0
                      ? periodTypes.filter(
                          (periodType) =>
                            periodType.value === selectedPeriodType
                        )
                      : defaultPeriodType
                  }
                  onChange={(event) => setSelectedPeriodType(event.value)}
                />
              </Col>

              <Col xs={12} md={4} lg={4}>
                <label>Period</label>
                <Select
                  placeholder="Select Period..."
                  options={periods}
                  value={
                    selectedPeriod > 0 && periods.length > 0
                      ? periods.filter(
                          (period) => period.value === selectedPeriod
                        )
                      : defaultPeriod
                  }
                  onChange={(event) => {
                    setSelectedPeriod(event.value);
                  }}
                />
              </Col>

              <Col xs={12} md={4} lg={3}>
                <label>Year</label>
                <Datetime
                  value={selectedYear}
                  dateFormat="YYYY"
                  timeFormat={false}
                  onChange={handleYearChange}
                  closeOnSelect
                />

                {isYearChangeError ? (
                  <Alert color="danger">{errorMessage}</Alert>
                ) : (
                  ""
                )}
              </Col>
            </Row>

            <Row>
              <label className="mt-4">Planned Annual Activity</label>
              <Select
                placeholder="Select Activity..."
                options={activities}
                onChange={(event) => setSelectedActivity(event.value)}
              />
            </Row>

            <Row>
              <Col className="mt-4" xs={12} md={6} lg={6}>
                <label>Milestone Details</label>
                <Input
                  type="textarea"
                  value={achievedActivityDetails}
                  onChange={(event) =>
                    setAchievedActivityDetails(event.target.value)
                  }
                />
              </Col>

              <Col className="mt-4" xs={12} md={3} lg={3}>
                <label>Milestone Value</label>
                <Input
                  type="number"
                  value={achievedActivityValue}
                  onChange={(event) =>
                    setAchievedActivityValue(event.target.value)
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col className="mt-4" xs={12} md={6} lg={6}>
                <label>Comments</label>
                <Input
                  type="textarea"
                  value={comments}
                  onChange={(event) => setComments(event.target.value)}
                />
              </Col>
            </Row>

            <Row>
              <Col className="mt-2" xs={12} md={9} lg={9}>
                <Button color="success" onClick={handleSubmit}>
                  {isSubmitting ? "Please wait ..." : "Save Milestone"}
                </Button>
              </Col>
            </Row>

            <Row>
              {showAlert ? (
                <Col className="mt-4" xs={12} md={9} lg={9}>
                  <Alert color={color}>{errorMessage}</Alert>
                </Col>
              ) : (
                ""
              )}
            </Row>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <div className="table-responsive">
              <Row>
                {showLoader ? (
                  <Spinner />
                ) : (
                  <table className="table table-hover mb-0">
                    <thead>
                      <tr>
                        <th style={{ width: "2rem" }}>#</th>
                        <th style={{ width: "2rem" }}>Activity</th>
                        <th style={{ width: "6rem" }}>Milestone Details</th>
                        <th style={{ width: "4rem" }}>Milestone Value</th>
                        <th style={{ width: "2rem" }}>Comments</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      {milestones.map((item, index) => (
                        <tr key={index}>
                          <td style={{ width: "2rem" }}>{index + 1}</td>
                          <td style={{ width: "2rem" }}>
                            {activities.length > 0
                              ? activities.filter(
                                  (record) => record.value === item.activity
                                )[0].number
                              : ""}
                          </td>
                          <td style={{ width: "6rem" }}>
                            {item.achieved_activity_details}
                          </td>
                          <td style={{ width: "4rem" }}>
                            {item.achieved_activity_value}
                          </td>
                          <td style={{ width: "2rem" }}>{item.comments}</td>
                          <td style={{ width: "2rem", textAlign: "center" }}>
                            <Link
                              className="btn-sm-danger"
                              onClick={(e) => handleDelete(item.id)}
                            >
                              Remove
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </Row>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default Main;
