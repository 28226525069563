import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  getOverallMilestones,
  getInstitutionMilestones,
} from "../../../helpers/backend_helper";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Collapse, Divider } from "antd";
import Spinner from "../../../components/Spinner/index";


const Main = () => {
  let navigate = useNavigate();
  let role = localStorage.getItem("role");

  let nf = new Intl.NumberFormat();

  const [overallMilestones, setOverallMilestones] = useState([]);
  const [institutionMilestones, setInstitutionMilestones] =
    useState([]);

  const [isRendering, setIsRendering] = useState(false);


  const fetchOverallMilestones = async (props) => {
    setIsRendering(true);
    try {
      getOverallMilestones(props).then((res) => {
        if (res === false) {
          navigate("/logout");
        } else {
          setOverallMilestones(res);
          console.log("role is", role);

          if (role !== "supervisor") {
            setIsRendering(false);
          }
        }
      });
    } catch (error) {
      console.error("Error loading submission data", error);
    }
  };

  const fetchInstitutionMilestones = async (props) => {
    try {
      getInstitutionMilestones(props).then((res) => {
        if (res === false) {
          navigate("/logout");
        } else {
          setInstitutionMilestones(res);
          console.log("role is", role);

          setIsRendering(false);
        }
      });
    } catch (error) {
      console.error("Error loading submission data", error);
    }
  };

  useEffect(() => {
    fetchOverallMilestones();
  }, []);

  useEffect(() => {
    fetchInstitutionMilestones();
  }, []);

  
  return (
    <div className="page-content">
      <Container fluid={true}>
      <Breadcrumbs
                title={localStorage.getItem("project")}
                breadcrumbItem="Log Framework"
              />

        {role !== "supervisor" && institutionMilestones !== undefined && institutionMilestones.length > 0
                    ? institutionMilestones.map((item, index) => (
                        <Collapse
                          key={index}
                          accordion
                          items={[
                            {
                              key: "1",
                              label: item.year_label,
                              children: item.period_data.map((periodItem, index) => (
                                <Collapse
                                  key={index}
                                  items={[
                                    {
                                      key: "1",
                                      label: (
                                        <span
                                          className="badge badge-soft-success"
                                          style={{
                                            fontSize: "13px",
                                            wordWrap: "normal",
                                          }}
                                        >
                                          {periodItem.period}
                                        </span>
                                      ),
                                      children: periodItem.activity_data.map(
                                        (activityItem, index) => (
                                          <Collapse
                                            key={index}
                                            items={[
                                              {
                                                key: "1",
                                                label: (
                                                  <span
                                                    className="badge badge-soft-info"
                                                    style={{
                                                      fontSize: "13px",
                                                      wordWrap: "normal",
                                                    }}
                                                  >
                                                    Activity: {activityItem.activity_number} - {activityItem.activity_description}

                                                  </span>
                                                ),
                                                children: (
                                                  <>
                                                    <Row className="mb-3">
                                                      <div className="table-responsive">
    
                                                        <table className="table table-hover mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Details</th>
                                                                    <th>Value</th>
                                                                    <th>Comments</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                              {activityItem.milestone.map(
                                                                (item, index) => (
                                                                  <tr key={index}>
                                                                    <td style={{ width:"2rem", alignItems: "left"}}>
                                                                      {index + 1} </td>
                                                                      <td style={{ width: "5rem"}}>
                                                                      <h6>{ item.achieved_activity_details}</h6>
                                                                    </td>
                                                                    <td style={{ width: "5rem"}}>
                                                                      <h6>{ item.achieved_activity_value}</h6>
                                                                    </td>
                                                                    <td style={{ width: "5rem"}}>
                                                                      <h6>{ item.comments}</h6>
                                                                    </td>
                                                                  </tr>
                                                                )
                                                              )}
                                                            </tbody>
                                                        </table>
                                                      </div>
                                                    </Row>
                                                  </>
                                                ),
                                              },
                                            ]}
                                          />
                                        )
                                      ),
                                    },
                                  ]}
                                />
                              )),
                            },
                          ]}
                        />
                      ))
                    : role === "supervisor" &&
                      overallMilestones !== undefined && institutionMilestones.length > 0
                    ? institutionMilestones.map((item, index) => (
                        <Collapse
                          key={index}
                          accordion
                          items={[
                            {
                              key: "1",
                              label: item.institution,
                              children: item.year_data.map((institutionItem, index) => (
                                <Collapse
                                  key={index}
                                  accordion
                                  items={[
                                    {
                                      key: "1",
                                      label: institutionItem.year_label,
                                      children: institutionItem.period_data.map(
                                        (periodItem, index) => (
                                          <Collapse
                                            key={index}
                                            items={[
                                              {
                                                key: "1",
                                                label: (
                                                  <span
                                                    className="badge badge-soft-success"
                                                    style={{
                                                      fontSize: "13px",
                                                      wordWrap: "normal",
                                                    }}
                                                  >
                                                    {periodItem.period}
                                                  </span>
                                                ),
                                                children: periodItem.activity_data.map(
                                                  (activityItem, index) => (
                                                    <Collapse
                                                      key={index}
                                                      items={[
                                                        {
                                                          key: "1",
                                                          label: (
                                                            <span
                                                              className="badge badge-soft-info"
                                                              style={{
                                                                fontSize: "13px",
                                                                wordWrap: "normal",
                                                              }}
                                                            >
                                                              {activityItem.activity_number}
                                                            </span>
                                                          ),
                                                          children: (
                                                            <>
                                                            <Row className="mb-3">
                                                              <div className="table-responsive">
            
                                                                <table className="table table-hover mb-0">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>#</th>
                                                                            <th>Details</th>
                                                                            <th>Value</th>
                                                                            <th>Comments</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                      {activityItem.milestone.map(
                                                                        (item, index) => (
                                                                          <tr key={index}>
                                                                            <td style={{ width:"2rem", alignItems: "left"}}>
                                                                              {index + 1} </td>
                                                                              <td style={{ width: "5rem"}}>
                                                                              <h6>{ item.achieved_activity_details}</h6>
                                                                            </td>
                                                                            <td style={{ width: "5rem"}}>
                                                                              <h6>{ item.achieved_activity_value}</h6>
                                                                            </td>
                                                                            <td style={{ width: "5rem"}}>
                                                                              <h6>{ item.comments}</h6>
                                                                            </td>
                                                                          </tr>
                                                                        )
                                                                      )}
                                                                    </tbody>
                                                                </table>
                                                              </div>
                                                            </Row>
                                                          </>
                                                          ),
                                                        },
                                                      ]}
                                                    />
                                                  )
                                                ),
                                              },
                                            ]}
                                          />
                                        )
                                      ),
                                    },
                                  ]}
                                />
                              )),
                            },
                          ]}
                        />
                      ))
                    : ""}
      </Container>
    </div>
  );
};

export default Main;
