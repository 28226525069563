import {config} from "../Constants";


let BASE_URL = config.url.API_URL;

//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//REAL LOGIN
export const POST_LOGIN = `${BASE_URL}/token/obtain/`;

//REFRESH JWT TOKEN
export const RENEW_JWT_TOKEN = `${BASE_URL}/token/refresh/`;

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";
export const GET_USER_PROFILE = `${BASE_URL}/user/`

//CALENDER
export const GET_EVENTS = "/events";
export const ADD_NEW_EVENT = "/add/event";
export const UPDATE_EVENT = "/update/event";
export const DELETE_EVENT = "/delete/event";
export const GET_CATEGORIES = "/categories";
export const GET_DATA_POINTS = `${BASE_URL}/data-point-institution-mappings/`;
export const GET_DATA_POINT_CATEGORIES = `${BASE_URL}/data-point-category-institution-mappings/`;
export const ADD_GET_UPDATE_INSTITUTIONAL_DATA = `${BASE_URL}/received-institutional-data/`;
export const GET_DRAFT_DATA = `${BASE_URL}/get-previous-quarter-draft-data/`;
export const GET_OVERALL_DRAFT_DATA = `${BASE_URL}/get-overall-previous-quarter-draft-data/`;
export const GET_DATAPOINT_REGIONAL_DRAFT_DATA = `${BASE_URL}/get-specific-data-point-regional-data/`;
export const GET_PREVIOUS_SPECIFIC_REGION_DRAFT_DATA = `${BASE_URL}/get-specific-region-quarter-draft-data/`;
export const GET_PERIODS = `${BASE_URL}/periods/`;
export const GET_PERIOD_TYPES = `${BASE_URL}/period-types/`;
export const GET_REGIONS = `${BASE_URL}/regions/`;
export const GET_PREVIOUS_PERIOD = `${BASE_URL}/get-previous-quarter/`;
export const GET_DEFAULT_PERIOD_TYPE = `${BASE_URL}/get-default-period-type/`;
export const GET_PROJECT_DETAILS = `${BASE_URL}/project-details/`;
export const DELETE_DATAPOINT_ITEM = `${BASE_URL}/delete-datapoint-item/`;
export const DELETE_DRAFT_ITEM = `${BASE_URL}/delete-draft-item/`;
export const DELETE_ACTIVITY = `${BASE_URL}/delete-activity/`;
export const DELETE_MILESTONE = `${BASE_URL}/delete-milestone/`;
export const EXPOSE_BI_DATA = `${BASE_URL}/expose-institutional-data-to-other-bis/`;
export const GET_FILE_TRACKER =  `${BASE_URL}/file-tracker/`;
export const GET_FILE_TRACKER_LOG =  `${BASE_URL}/file-tracker-log/`;

export const POST_FILE =  `${BASE_URL}/post-file-details/`;
export const UPDATE_FILE =  `${BASE_URL}/update-file-details/`;

export const GET_INSTITUTIONS =  `${BASE_URL}/institutions/`;
export const GET_INVESTIGATION_OFFICERS=  `${BASE_URL}/get-investigation-officers/`;
export const UPDATE_FILE_TRACKER=  `${BASE_URL}/update-file-tracker/`;
export const CREATE_FILE_TRACKER=  `${BASE_URL}/create-file-tracker/`;
export const GET_COORDINATORS=  `${BASE_URL}/get-coordinators/`;

export const FILES = `${BASE_URL}/files/`
export const GET_FILE_SUMMARY = `${BASE_URL}/get-file-summary/`

export const GET_SUBMITTED_DATA = `${BASE_URL}/get-submitted-data/`
export const GET_SUBMITTED_DATA_ALL_INSTITUTIONS = `${BASE_URL}/get-submitted-data-all-institutions/`

// Overview Visualizations
export const GET_OVERVIEW_DATA = `${BASE_URL}/get-overview-data/`
export const GET_REASONS = `${BASE_URL}/reasons/`

// Trend Visualizations
export const GET_TRENDS = `${BASE_URL}/get-trends/`

//Authentication Endpoints
export const UPDATE_PASSWORD = `${BASE_URL}/change-password/`

//Questionnaires
export const POST_QUESTIONNAIRE = `${BASE_URL}/post-questionnaire/`
export const GET_QUESTIONNAIRE_RESULTS = `${BASE_URL}/get-questionnaire-results/`

//Activity and Milestone Reporting
export const ACTIVITY = `${BASE_URL}/activity/`
export const MILESTONE_REPORTING = `${BASE_URL}/milestone-reporting/`
export const GET_OVERALL_MILESTONES = `${BASE_URL}/get-overall-milestones/`
export const GET_INSTITUTION_MILESTONES = `${BASE_URL}/get-institution-milestones/`


